@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Avenir Next Condensed";
  src: url("./assets/fonts/AvenirNextCondensed/AvenirNextCondensed-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/AvenirNextCondensed/AvenirNextCondensed-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next DemiBold";
  src: url("./assets/fonts/AvenirNextDemiBold/AvenirNextCyr-Demi.woff2")
      format("woff2"),
    url("./assets/fonts/AvenirNextDemiBold/AvenirNextCyr-Demi.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
