@import "~react-image-gallery/styles/css/image-gallery.css";
@import '~video-react/dist/video-react.css';

label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    font-size: large;
}

input,
textarea {
    padding: 4px;
    border-radius: 4px;
    background: #f7f7f7;
    border: 1px solid #000;
}

.video-react .video-react-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 100%);
    border: none;
}

.video-react-big-play-button:before {
    color: #000;
}

.video-react .video-react-video {
    cursor: pointer;
}

.nopad {
    padding-top: 0 !important;
}

.primary-button {
    background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 100%);
    position: relative;
    padding: 3px;
    display: inline-block;
    border-radius: 7px;
}

.primary-button span {
    display: inline-block;
    background: white;
    color: black;
    text-transform: capitalize;
    padding: 5px 10px;
    border-radius: 5px;
}

.header-button span {
    background: #191919;
    color: white;
}

.rainbow-border {
    background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 100%);
    position: relative;
    padding: 3px;
    display: inline-block;
    border-radius: 7px;
}